import * as React from "react";
import Layout from "../components/layout/layout";
import { Container, Row, Col } from "react-bootstrap";
import "../general.scss";
// markup
const Impressum = () => {
  return (
    <>
      <Layout>
        <div
          class="skrrrt"
          style={{
            backgroundColor: "#25acbe",
            backgroundImage: "linear-gradient(58deg, #25acbe 0%, #6dd4e2 100%)",
          }}
        >
          <Container fluid="md">
            <Row className="justify-content-xs-center">
              <Col>
                <h1
                  style={{
                    color: "white",
                    padding: "40px 0px",
                    fontSize: "45px",
                  }}
                  className="text-center"
                >
                  Impressum
                </h1>
              </Col>
            </Row>
          </Container>
        </div>
        <Container style={{ padding: "25px", fontSize: "20px" }}>
          <Row>
            <Col xs={3}>
              <p>Xavier Webdesign</p>
              <p>Glärnischstrasse 20</p>
              <p>8820 Wädenswil</p>
              <p>
                <a href="mailto:hello@netaro.ch">hello@netaro.ch</a>
              </p>
            </Col>
            <Col>
              <h2>Vertretungsberechtigte Person(en)</h2>
              <p>Kevin Xavier P, Gesch&auml;ftsf&uuml;hrer</p>
              <br />
              <br />
              <h2>Haftungsausschluss</h2>
              <p>
                Der Autor &uuml;bernimmt keinerlei Gew&auml;hr hinsichtlich der
                inhaltlichen Richtigkeit, Genauigkeit, Aktualit&auml;t,
                Zuverl&auml;ssigkeit und Vollst&auml;ndigkeit der Informationen.
              </p>
              <p>
                HaftungsansPr&uuml;che gegen den Autor wegen Sch&auml;den
                materieller oder immaterieller Art, welche aus dem Zugriff oder
                der Nutzung bzw. Nichtnutzung der ver&ouml;ffentlichten
                Informationen, durch Missbrauch der Verbindung oder durch
                technische St&ouml;rungen entstanden sind, werden
                ausgeschlossen.
              </p>
              <p>
                Alle Angebote sind unverbindlich. Der Autor beh&auml;lt es sich
                ausdr&uuml;cklich vor, Teile der Seiten oder das gesamte Angebot
                ohne besondere Ank&uuml;ndigung zu ver&auml;ndern, zu
                erg&auml;nzen, zu l&ouml;schen oder die Ver&ouml;ffentlichung
                zeitweise oder endg&uuml;ltig einzustellen.
              </p>
              <br />
              <br />
              <h2>Haftungsausschluss f&uuml;r Links</h2>
              <p>
                Verweise und Links auf Webseiten Dritter liegen ausserhalb
                unseres Verantwortungsbereichs. Es wird jegliche Verantwortung
                f&uuml;r solche Webseiten abgelehnt. Der Zugriff und die Nutzung
                solcher Webseiten erfolgen auf eigene Gefahr des jeweiligen
                Nutzers.
              </p>
              <br />
              <br />
              <h2>Urheberrechte</h2>
              <p>
                Die Urheber- und alle anderen Rechte an Inhalten, Bildern, Fotos
                oder anderen Dateien auf dieser Website, geh&ouml;ren
                ausschliesslich lsKevin Xavier P. oder den speziell genannten
                Rechteinhabern. F&uuml;r die Reproduktion jeglicher Elemente ist
                die schriftliche Zustimmung des Urheberrechtstr&auml;gers im
                Voraus einzuholen.
              </p>
              <br />
              <br />
              <span style={{ color: "white" }}>Quelle:</span>{" "}
              <a
                href="https://www.swissanwalt.ch"
                target="_blank"
                rel="noreferrer"
              >
                SwissAnwalt
              </a>
            </Col>
          </Row>
        </Container>
      </Layout>
    </>
  );
};

export default Impressum;
